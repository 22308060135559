$(document).ready(function() {
  $('.page-loader').addClass("loaded");
})

$(window).on('load', function() {
  // Скрываем главное меню
  // Плагин:jquery.ba-throttle-debounce
  function FixedMenu(element, from) {
    this._from = from;
    this._element = element;
    this._$win = $(window);
    this._elemHasClassChanged = false;
    this.init();
  }

  FixedMenu.prototype.init = function() {
    var self = this;
    this._$win.on('scroll', $.throttle(35, function() { self._handleScroll(); }));

    if (this._$win.scrollTop() >= this._from) {
        !this._elemHasClassChanged && this._element.addClass('fixed');
        this._elemHasClassChanged = true;
    } else {
        this._elemHasClassChanged && this._element.removeClass('fixed');
        this._elemHasClassChanged = false;
    }
  }

  FixedMenu.prototype._handleScroll = function() {
    if (this._$win.scrollTop() >= this._from) {
        !this._elemHasClassChanged && this._element.addClass('fixed');
        this._elemHasClassChanged = true;
    } else {
        this._elemHasClassChanged && this._element.removeClass('fixed');
        this._elemHasClassChanged = false;
    }
  }

  var a = new FixedMenu($('header'), 300);

  $('.header-slider').flexslider({
    animation: "fade",
    directionNav: false,
    slideshowSpeed: 4000,
  });

  var $window = $(window),
  flexslider = { vars:{} };

  function getGridSize() {
    return (window.innerWidth < 600) ? 2 :
           (window.innerWidth < 900) ? 3 : 4;
  }

  $('.partners__slider').flexslider({
    animation: "slide",
    animationLoop: true,
    slideshowSpeed: 4000,
    directionNav: false,
    itemWidth: 255,
    itemMargin: 30,
    minItems: getGridSize(),
    maxItems: getGridSize()
  });

  // check grid size on resize event
  $window.resize(function() {
    var gridSize = getGridSize();

    flexslider.vars.minItems = gridSize;
    flexslider.vars.maxItems = gridSize;
  });

  $('.order_btn').magnificPopup({
    type: 'inline',
    removalDelay: 500, //delay removal by X to allow out-animation
    mainClass: 'mfp-zoom-in'
  });

  // $("#menu").mmenu({
  //   // wrappers: ["wordpress"],
  //   // counters: true,
  //   extensions  : [ "position-bottom", "fullscreen", "theme-black", "listview-50", "fx-panels-slide-up", "fx-listitems-drop", "border-full" ],
  //   navbar    : {
  //     title     : "МЕНЮ"
  //   },
  //   navbars   : [{
  //     height  : 2,
  //     content : [
  //       '<a href="tel:+79782066311" class="mmenu-link phone-link phone-link__mmenu" title="Позвонить в FoxVision"><i class="icon-phone"></i></a>',
  //       '<a href="mailto:fox-crimea@ya.ru" class="mail-link_mmenu mmenu-link"><i class="icon-mail-alt"></i></a></div>',
  //       '<a href="tg://resolve?domain=Fox_Vision" target="_blank" title="Связаться с нами в Telegram" class="telegram-link mmenu-link"></a>',
  //       '<a href="viber://chat?number=+79782066311" target="_blank" title="Связаться с нами в Viber" class="viber-link mmenu-link"></a>',
  //       '<a href="https://api.whatsapp.com/send?phone=+79782066311" target="_blank" title="Связаться с нами в WhatsApp" class="whatsapp-link mmenu-link"></a>',
  //     ]
  //   }, {
  //     content : ["prev", "title", "close" ]
  //   }]
  // }, {

  // });
  // var apiMmenu = $("#menu").data( "mmenu" );
  // $(".mmenu_close").click(function(e) {
  //   e.preventDefault();
  //   apiMmenu.close();
  // });


  new Mmenu(
    document.querySelector('#menu'),
    {
      extensions  : [ "position-bottom", "fullscreen", "theme-black", "listview-50", "fx-panels-slide-up", "fx-listitems-drop", "border-full" ],
      navbar    : {
        title     : "МЕНЮ"
      },
      navbars   : [
        {
        "position": "top",
        "content" : [
          '<div><a href="tel:+79782066311" class="mmenu-link phone-link phone-link__mmenu" title="Позвонить в FoxVision"><i class="icon-phone"></i></a></div>',
          '<div><a href="mailto:fox-crimea@ya.ru" class="mail-link_mmenu mmenu-link"><i class="icon-mail-alt"></i></a></div></div>',
          '<div><a href="tg://resolve?domain=Fox_Vision" target="_blank" title="Связаться с нами в Telegram" class="telegram-link mmenu-link"></a></div>',
          '<div><a href="viber://chat?number=+79782066311" target="_blank" title="Связаться с нами в Viber" class="viber-link mmenu-link"></a></div>',
          '<div><a href="https://api.whatsapp.com/send?phone=+79782066311" target="_blank" title="Связаться с нами в WhatsApp" class="whatsapp-link mmenu-link"></a></div>',
        ]
      }]
    }
  );

  document.addEventListener( 'click', function( evnt ) {
    var anchor = evnt.target.closest( 'a[href^="#/"]' );
    if ( anchor ) {
      alert('Thank you for clicking, but that\'s a demo link.');
      evnt.preventDefault();
    }
  });

  $("a.scrollto").click(function() {
    var elementClick = $(this).attr("href")
    var destination = $(elementClick).offset().top;
    jQuery("html:not(:animated),body:not(:animated)").animate({
      scrollTop: destination
    }, 800);
    return false;
  });


  $('input[type=tel]').mask('+7 (000) 000-00-00');

  //Карта
  ymaps.ready(function () {
    var myMap = new ymaps.Map('map', {
            center: [44.960239,34.117595],
            zoom: 17,
            controls: ['zoomControl', 'typeSelector']
        }),
        myPlacemark = new ymaps.Placemark([44.960810,34.117009], {
            iconContent: 'Fox-Vision'
        }, {
            preset: "islands#dotIcon",
            iconColor: '#ea5921'
        });
    myMap.geoObjects.add(myPlacemark);
  });
  new WOW().init();

    $(".contact-form").submit(function () {
        var formID = $(this).attr('id');
        var formNm = $('#' + formID);
        var message = $(formNm).find(".form-message");
        var formTitle = $(formNm).find(".form-title");
        $.ajax({
            type: "POST",
            url: '/telegram_sender.php',
            data: formNm.serialize(),
            success: function (data, text) {
              alert(data);
              $.magnificPopup.close();
              $(formNm).trigger("reset");
            },
            error: function (jqXHR, text, error) {
                // Вывод сообщения об ошибке отправки
                // message.html(error);
                // formTitle.css("display","none");
                // setTimeout(function(){
                //   formTitle.css("display","block");
                //   message.html('');
                //   $('input').not(':input[type=submit], :input[type=hidden]').val('');
                // }, 3000);
                alert(text);
            }
        });
        return false;
    });
});
